<template>
  <div class="meet-resolver">
    <img class="logo" alt="Selia" src="@/assets/logo_with_name.svg">

    <div class="card">
      <h1>Bienvenido a Selia</h1>

      <a class="link-block" :href="consultantLink" target="_self">Continuar como consultante</a>
    </div>

    <h3>¿Eres especialista?</h3>

    <a class="link" :href="specialistLink" target="_self">Continuar como especialista</a>
  </div>
</template>

<script>
export default {
  name: 'TwilioMeetResolver',
  data () {
    return {
      consultantLink: String,
      specialistLink: String
    }
  },
  mounted () {
    const sessionPath = `/sessions/${this.$route.params.id}`

    this.consultantLink = `${process.env.VUE_APP_USERS_URL}${sessionPath}`
    this.specialistLink = `${process.env.VUE_APP_SPECIALIST_URL}${sessionPath}`
  }
}
</script>

<style lang="sass">
  .meet-resolver
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100vh

    a
      text-decoration: none

    .logo
      height: 30px

    .card
      border: 2px solid #f0ebfa
      border-radius: 16px
      padding: 24px 16px
      text-align: center
      background-color: #f5f5f5
      margin: 36px 0 52px

      h1
        color: #151516
        font-size: 20px
        font-weight: bold
        margin-bottom: 16px

      .link-block
        display: block
        color: #FFFFFF
        background-color: #8350e6
        font-size: 14px
        font-weight: bold
        padding: 10px
        width: 280px
        border-radius: 12px
        cursor: pointer

    h2
      color: #151516
      font-size: 14px
      margin-bottom: 16px

    .link
      color: #8350e6
      font-size: 14px
      font-weight: bold
      cursor: pointer
</style>
